import './bootstrap';
import './tools/link_modal.js';
import './tools/delivery_table.js';
import './tools/menu.js';
import './tools/platformSwitcher.js';
import './tools/forms.js';
import './tools/chart.js';

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();
