// As the platform switcher uses the chartjs dataset structure we're using it for every instance a platform switcher is used (charts, tables and world map charts)

window.initializeChartAndPlatformSwitcher = function(chartId, data, tableConfig) {
    const canvas = document.getElementById(chartId);
    const ctx = canvas.getContext('2d');
    const stacked = canvas.dataset.stacked === 'true';
    const table = $(`#table-${chartId}`).length;
    const map = $(`#world-${chartId}`).length;

    data.datasets.forEach(dataset => {
        if (dataset.fill) {
            const baseColor = dataset.borderColor || dataset.backgroundColor;

            const gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, `${hexToRgba(baseColor, 0.5)}`);
            gradient.addColorStop(1, `${hexToRgba(baseColor, 0)}`);

            // Assign the gradient to the dataset's backgroundColor
            dataset.backgroundColor = gradient;
        }
    });

    const config = {
        type: canvas.dataset.chartType,
        data: {
            labels: data.labels,
            datasets: data.datasets
        },
        options: {
            responsive: true,
            aspectRatio: 2,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            let label = context.dataset.label || '';
                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += context.parsed.y;
                            }
                            return label;
                        }
                    }
                },
            },
            scales: {
                x: {
                    stacked: stacked,
                },
                y: {
                    stacked: stacked,
                    ticks: {
                        precision: 0
                    }
                }
            }
        }
    };

    const chart = new Chart(ctx, config);
    let activePlatforms = new Set();

    function toggleDataset(activePlatforms) {
        const activePlatformLabels = Array.from(activePlatforms);
        chart.data.datasets.forEach((dataset) => {
            dataset.hidden = !activePlatformLabels.includes(dataset.label);
        });
        chart.update();

        if (table) {
            updateTable(chartId, data, activePlatforms, tableConfig);
        }

        if (map) {
            updateLeafletMap(chartId, data, activePlatforms);
        }
    }

    function activateAllPlatforms() {
        $('.platform-btn').not('.all-platforms-btn').removeClass('active');
        $(`#all-platforms-btn-${chartId}`).addClass('active');
        activePlatforms = new Set(data.datasets.map(dataset => dataset.label));
        toggleDataset(activePlatforms);
    }

    function deactivateAllPlatforms() {
        activePlatforms.clear();
    }

    $(`[data-chart-id="${chartId}"] .platform-btn`).click(function(event) {
        event.preventDefault();
        const platform = $(this).find('span').text();
        const isAllPlatformsButton = $(this).attr('id') === `all-platforms-btn-${chartId}`;
        const allIsActive = $(`#all-platforms-btn-${chartId}`).hasClass('active');

        if (allIsActive) {
            deactivateAllPlatforms();
        }

        if (isAllPlatformsButton) {
            if (!$(this).hasClass('active')) {
                activateAllPlatforms();
            }
        } else {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                activePlatforms.delete(platform);

                if (activePlatforms.size === 0) {
                    activateAllPlatforms();
                } else {
                    toggleDataset(activePlatforms);
                }
            } else {
                $(`#all-platforms-btn-${chartId}`).removeClass('active');
                $(this).addClass('active');
                activePlatforms.add(platform);
                toggleDataset(activePlatforms);
            }
        }
    });

    // Initialize with all platforms active
    activateAllPlatforms();
}

window.initializeTable = function(chartId, data, tableConfig) {
    updateTable(chartId, data, new Set(data.datasets.map(dataset => dataset.label)), tableConfig);
}

function updateTable(chartId, chartData, activePlatforms, tableConfig) {
    const tableContainer = document.getElementById(`table-${chartId}`);
    const table = tableContainer.querySelector('table');

    if (!table) {
        console.error('Table structure is missing.');
        return;
    }

    const tbody = table.querySelector('tbody');
    tbody.innerHTML = ''; // Clear existing rows

    // Determine if we need to sort the data
    const sortableColumn = tableConfig.columns.find(col => col.sortable);
    if (sortableColumn) {
        // Add a calculated field to each item in extraData if needed
        const dataWithCalculatedFields = chartData.extraData.map((item, index) => {
            // Calculate the value for sortable column if it requires custom calculation
            if (sortableColumn.key === 'total_streams') {
                let totalStreams = 0;
                for (const platform of activePlatforms) {
                    for (const set of chartData.datasets) {
                        if (set.label === platform) {
                            totalStreams += set.data[index];
                        }
                    }
                }
                return { ...item, total_streams: totalStreams };
            }
            return item;
        });

        // Sort the data based on the key of the sortable column
        dataWithCalculatedFields.sort((a, b) => {
            const valueA = a[sortableColumn.key];
            const valueB = b[sortableColumn.key];
            // Sorting in descending order (you can adjust if you want ascending)
            return valueB - valueA;
        });

        // Render sorted rows
        dataWithCalculatedFields.forEach((item, index) => {
            const row = document.createElement('tr');
            row.className = 'bg-gray-dark';

            // Dynamically generate cells based on the table configuration
            tableConfig.columns.forEach((col) => {
                const cell = document.createElement('td');
                cell.className = col.className || '';

                if (col.key === 'rank') {
                    cell.innerText = index + 1 + '.';
                } else if (col.key === 'total_streams') {
                    cell.innerText = col.formatter ? col.formatter(item.total_streams) : item.total_streams;
                } else if (col.template) {
                    cell.innerHTML = col.template(item);
                } else {
                    cell.innerText = item[col.key];
                }

                row.appendChild(cell);
            });

            tbody.appendChild(row);

            // Add a spacer row for vertical spacing
            const spacerRow = document.createElement('tr');
            spacerRow.className = 'h-5';
            tbody.appendChild(spacerRow);
        });
    } else {
        // No sorting, just render the table normally
        chartData.extraData.forEach((item, index) => {
            let totalStreams = 0;
            for (const platform of activePlatforms) {
                for (const set of chartData.datasets) {
                    if (set.label === platform) {
                        totalStreams += set.data[index];
                    }
                }
            }

            const row = document.createElement('tr');
            row.className = 'bg-gray-dark';

            // Dynamically generate cells based on the table configuration
            tableConfig.columns.forEach((col) => {
                const cell = document.createElement('td');
                cell.className = col.className || '';

                if (col.key === 'rank') {
                    cell.innerText = index + 1 + '.';
                } else if (col.key === 'total_streams') {
                    cell.innerText = col.formatter ? col.formatter(totalStreams) : totalStreams;
                } else if (col.template) {
                    cell.innerHTML = col.template(item);
                } else {
                    cell.innerText = item[col.key];
                }

                row.appendChild(cell);
            });

            tbody.appendChild(row);

            // Add a spacer row for vertical spacing
            const spacerRow = document.createElement('tr');
            spacerRow.className = 'h-5';
            tbody.appendChild(spacerRow);
        });
    }
}

window.convertChartDataToMapData = function (chartData) {
    const { labels, datasets } = chartData;
    const mapData = [];

    labels.forEach((label, index) => {
        let totalValue = 0;
        let color = '#000000';

        // Sum up all the platform values for this location
        datasets.forEach(dataset => {
            if (!dataset.hidden) {
                totalValue += dataset.data[index];
                color = dataset.backgroundColor; // Use the last color, or customize to a specific one
            }
        });

        if (totalValue > 0) { // Only add locations with data
            mapData.push({
                city: label,
                lat: countryCoordinates[label].lat,
                lon: countryCoordinates[label].lon,
                totalValue: totalValue,
                color: color
            });
        }
    });

    return mapData;
}

// Global variable to store the map instance
let leafletMapInstances = {};

function updateLeafletMap(chartId, data, activePlatforms) {
    // Check if a map instance already exists for the given chartId
    if (leafletMapInstances[chartId]) {
        // Remove the existing map instance
        leafletMapInstances[chartId].remove();
    }

    const mapData = convertChartDataToMapData(data);
    // Calculate the total value across all locations
    let totalGlobalValue = 0;
    mapData.forEach(point => {
        totalGlobalValue += point.totalValue;
    });

    // Define the maximum radius in meters and maximum allowed percentage
    const maxRadius = 1000000;
    const maxPercentage = 0.30; // Of max radius

    // Initialize Leaflet map
    var map = L.map(`world-${chartId}`, {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: 0.1,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        boxZoom: false,
        keyboard: false,
        tap: false
    }).setView([35, 6], 1.8);

    // Store the map instance in the global variable
    leafletMapInstances[chartId] = map;

    L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}{r}.png', {
        noWrap: true,
        minZoom: 1,
        maxZoom: 2,
        opacity: 1
    }).addTo(map);

    // Add data points to the map
    mapData.forEach(function(point) {
        // Calculate the radius based on the percentage of the total global value
        const percentage = point.totalValue / totalGlobalValue;
        let radius = percentage * maxRadius * 2; // Adjust the multiplier for base scaling

        // Ensure the radius doesn't exceed the max percentage of the max radius
        radius = Math.min(radius, maxRadius * maxPercentage);

        L.circle([point.lat, point.lon], {
            color: '#424242',
            fillColor: point.color,
            fillOpacity: 0.5,
            borderColor: '#424242',
            radius: radius
        }).addTo(map).bindPopup(`${point.city}: ${point.totalValue} (${(percentage * 100).toFixed(2)}%)`, {
            autoPan: false
        });
    });

    setInterval(function() {
        map.invalidateSize();
    }, 2000);
}

// Helper function to format numbers (e.g., adding commas)
function number_format(number) {
    return number.toLocaleString(); // Adjust formatting as needed
}

function hexToRgba(hex, alpha) {
    let r = 0, g = 0, b = 0;

    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    } else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}