//find all forms where class="disabled" and disable them

    var forms = document.querySelectorAll('form.disabled');
    forms.forEach(function(form) {
        let forced = false;

        //find all inputs, selects and textareas and disable them
        var inputs = form.querySelectorAll('input, select, textarea');
        inputs.forEach(function(input) {

            if(input.getAttribute('data-enabled')=='enabled'){
                input.disabled = false;
                forced = true;
            }
            else {
                input.disabled = true;
            }
        });


        //find all .btn-group a type=button
        var buttons = form.querySelectorAll('.btn-group a[type="button"]:not(.btn), .btn');
        buttons.forEach(function(button) {
            if(button.getAttribute('data-enabled')!='enabled') {
                button.disabled = true;
                button.classList.add('disabled');
                button.removeAttribute('onclick');
                button.removeAttribute('href');
            }
        });

        if(!forced){
            form.addEventListener('submit', function(e) {
                e.preventDefault();
            });

            //find all buttons with type is submit and .btn and disable them and add class "disabled"
            var buttons = form.querySelectorAll('button[type="submit"]');
            buttons.forEach(function(button) {
                button.disabled = true;
                button.classList.add('disabled');
            });
        }
    });
