//use chart.js to create a chart
import Chart from 'chart.js/auto';


//if document is loaded, create the chart
document.addEventListener('DOMContentLoaded', function() {
    const ctx = document.getElementById('streams-chart');
    if(ctx){
        console.log('ctx',ctx);
        //create the chart
        new Chart(ctx, window.ctxData);
    }
    else{
        console.log('no ctx');
    }

    let periodSelector = document.getElementById('periodSelector');
    if(periodSelector){
        periodSelector.addEventListener('change', function(){
            let period = periodSelector.value;
            //get url without query string
            let url = window.location.href.split('?')[0];
            //add query string
            url += '?period=' + period;
            document.location.href = url;
        });
    }
});

